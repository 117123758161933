<template>
  
      <v-card
        class="mx-auto my-12"
        max-width="374">

        <div class="banner">
          <v-img src="@/assets/images/banner.png"></v-img>
        </div>

        <v-card-text v-if="!loading">
          
          <v-form
            ref="form"
            v-model="valido"
            lazy-validation>
            
            <v-text-field
              ref="login"
              v-model="login"
              :rules="loginRules"
              :label="$i18n.t('Login')"
              required>
            </v-text-field>

            <v-text-field
              v-model="senha"
              :rules="senhaRules"
              type="password"
              :label="$i18n.t('Senha')"
              @keyup.enter.native="acessa"
              required>
            </v-text-field>

          </v-form>

          {{ message }}
        
        </v-card-text>

        <div class="text-center mg-10" v-if="loading">
          <v-progress-circular
            indeterminate
            color="primary">
          </v-progress-circular>
        </div>

        <v-card-actions v-if="!loading">

          <div class="w100">
            <v-row>
              <v-col align="center" cols="12">
                <v-btn
                  color="primary"
                  elevation="2"
                  @click="acessa"
                  :disabled="!validateForm">
                  {{$i18n.t('Acessar')}}
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col align="left" lg="6" cols="6" sm="6">
                <div
                  class="link-home"
                  @click="esqueciMinhaSenha">
                  {{$i18n.t('EsqueciMinhaSenha')}}
                </div>
              </v-col>

              <v-col align="right" cols="6" lg="6" sm="6">
                <div
                  class="link-home"
                  @click="acessaSemCredencial">
                  {{$i18n.t('SemCredencial')}}
                </div>
              </v-col>
            </v-row>
          </div>
          
        </v-card-actions>
        
      </v-card>
  
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      valido: false,
      login: '',
      loginRules: [
        v => !!v
      ],
      senha: '',
      senhaRules: [
        v => !!v
      ],
      message: null
    }),
    computed: {
        validateForm() {
          return this.valido && this.login != '' && this.senha != '';
        },
    },
    methods: {
      acessa() {
        if (this.validateForm) {
            this.loading = true;
            let data = {
              cpfCnpjIdent: this.login,
              senha: this.senha
            };
            this.$http.post('/login', data)
              .then(request => this.loginSuccessful(request))
              .catch((e) => this.loginFailed(e));
          } else {
            this.$refs.login.focus();
          }
      },
      loginSuccessful (req) {
          if (!req.data.token) {
              this.loginFailed();
              return;
          }
          this.$auth.signIn(req);
          this.$router.push('/rastrear');
      },
      loginFailed (e) {
        this.$auth.signOut();
        this.$msg.erroFast(this.$i18n.t('LoginError'));
        this.loading = false;
        console.error(e);
        this.message = e;
      },
      acessaSemCredencial() {
        this.$router.push('/rastrear');
      },
      esqueciMinhaSenha() {
        this.$router.push('/login/recupera');
      }
    },
    mounted() {
      this.$refs.login.focus();
    }
  }
</script>
<style lang="scss" scoped>
  .banner {
    width: 100%;
    padding: 14px;
    padding-left: 34px;
    padding-right: 34px;
    display: flex;
    align-items: center;
  }
</style>